import React from 'react';
import Page from 'components/page';
import loadable from '@loadable/component';
import Footer from 'components/footer';

import { Typography } from '@material-ui/core';

import useStyles from './styles';

const LazySupportHomeView = loadable(() => import('views/support/documents'));

const SupportNavigationPage = () => {
  const styles = useStyles();

  return (
        <Page title="Support" description={'Get Support or help using Total Loyalty'}>
          <Typography variant="h2" component="h1" className={styles.heading} align="center">
            Company Documents
          </Typography>
          <LazySupportHomeView/>
          <Footer />
        </Page>
  );
};

export default SupportNavigationPage;
